import React, { useEffect, useState } from 'react'
import { IFrame } from '../../../../components/Iframe/Ifram'
import ConvertHtmlToJSX from '../../../../components/ConvertHtmlToJSX/ConvertHtmlToJSX'
import { toast } from 'react-hot-toast'
import { createTemplate } from '../../../../services/api/api-services'
import { errorStyle } from '../../../../utils/strings/Strings'

export default function UploadCardLayout() {
    const [htmlCode, setHtmlCode] = useState('')
    const [cssCode, setCssCode] = useState('')
    const [thumbnail, setThumbnail] = useState(null)
    const [htmlCodeError, setHtmlCodeError] = useState('')
    const [cssCodeError, setCssCodeError] = useState('')
    const [thumbnailError, setThumbnailError] = useState(null)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
    }, [])

    const handleHtmlCode = (event) => {
        setHtmlCode(event.target.value)
    }

    const handleCssCode = (event) => {
        setCssCode(event.target.value)
    }

    const handleImageUpload = (event) => {
        const [file] = event.target.files
        if (file) {
            document.getElementById("imagePreview").src = URL.createObjectURL(file)
        }
        setThumbnail(event.target.files[0])
    }

    const handleFormValidation = (event) => {
        event.preventDefault()
        if (htmlCode === '' || cssCode === '' || thumbnail === null) {
            if (htmlCode === '') {
                setHtmlCodeError(true)
            } else {
                setHtmlCodeError(false)
            }
            if (cssCode === '') {
                setCssCodeError(true)
            } else {
                setCssCodeError(false)
            }
            if (thumbnail === null) {
                setThumbnailError(true)
            } else {
                setThumbnailError(false)
            }
        } else {
            handleSubmitForm()
        }
    }

    const handleSubmitForm = () => {
        setDisabled(true)
        let data = new FormData()
        data.append('html', htmlCode)
        data.append('css', cssCode)
        data.append('js', 'javascript')
        data.append('image', thumbnail)
        toast.promise(createTemplate(data), {
            loading: 'Saving...',
            success: <b>Save successfully</b>,
            error: <b>Something went wrong!</b>
        }).then((response) => {
            console.log(response)
            setDisabled(false)
            resetForm();
        }).catch((err) => {
            console.log(err)
        })
    }

    const resetForm = () => {
        setThumbnail(null);
        setHtmlCode("")
        setCssCode("")
        document.querySelector("form").reset()
        document.getElementById("imagePreview").src = "#"
    }

    return (
        <div className='container'>
            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='nk-block-head nk-block-head-sm'>
                        <div className='nk-block-between g-3'>
                            <div className='nk-block-head-content'>
                                <h3 className='nk-block-title page-title'>Create Card</h3>
                                <div className='nk-block-des text-soft'>
                                    <p>
                                        Upload card source code (HTML, CSS)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='nk-block'>
                        <div className='row g-gs'>
                            <div className='col-lg-8'>
                                <form onSubmit={handleFormValidation}>
                                    <div className='card card-bordered'>
                                        <div className='card-inner'>
                                            <div className='form-group'>
                                                <label className='form-label' htmlFor='customFileLabel'>
                                                    Thumbnail
                                                </label>
                                                <div className='form-control-wrap'>
                                                    <div className='form-file'>
                                                        <input onChange={handleImageUpload} type='file' className='form-file-input' id='customFile' />
                                                        <label style={thumbnailError ? errorStyle : {}} className='form-file-label' htmlFor='customFile'>
                                                            {thumbnail ? thumbnail?.name : "Choose file"}
                                                        </label>
                                                    </div>
                                                    {thumbnailError && <small className='invalid-feedback d-block'>Image must be filled out</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card card-bordered'>
                                        <div className='card-inner'>
                                            <div className='card-head'>
                                                <h5 className='card-title'>HTML</h5>
                                            </div>
                                            <div className='form-group'>
                                                <div className='form-control-wrap'>
                                                    <textarea
                                                        style={htmlCodeError ? errorStyle : {}}
                                                        onChange={handleHtmlCode}
                                                        rows={8}
                                                        type='text'
                                                        className='form-control'
                                                        id='html' />
                                                    {htmlCodeError && <small className='invalid-feedback d-block'>HTML must be filled out</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card card-bordered'>
                                        <div className='card-inner'>
                                            <div className='card-head'>
                                                <h5 className='card-title'>CSS</h5>
                                            </div>
                                            <div className='form-group'>
                                                <div className='form-control-wrap'>
                                                    <textarea
                                                        style={thumbnailError ? errorStyle : {}}
                                                        onChange={handleCssCode}
                                                        rows={8}
                                                        type='text'
                                                        className='form-control'
                                                        id='css' />
                                                    {cssCodeError && <small className='invalid-feedback d-block'>Css must be filled out</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <button type='submit' disabled={disabled} className='btn btn-primary'>
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className='col-lg-4'>

                                <div className='card card-bordered h-100'>
                                    <div className='card-inner'>
                                        <div className='card-head'>
                                            <h5 className='card-title'>Preview</h5>
                                        </div>
                                        <img id='imagePreview' src={"#"} />
                                        {/* <ConvertHtmlToJSX >
                                            {htmlCode}
                                        </ConvertHtmlToJSX> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
