import React from "react";
import { Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { LOGO, LOGO_DARK, LOGO_LIGHT } from "../../utils/images/Images";

export default function Header({ isAuth }) {
    const handleLogout = () => {
        localStorage.clear();

        toast.success("Logout successfully!");
        isAuth && isAuth();
    };
    return (
        <div className="nk-header nk-header-fixed is-light">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ms-n1">
                        <a
                            href="#"
                            className="nk-nav-toggle nk-quick-nav-icon"
                            id="headerButton"
                        >
                            <em className="icon ni ni-menu" />
                        </a>
                    </div>
                    <div className="nk-header-brand d-xl-none">
                        <a className="logo-link">
                            <img
                                className="logo-light logo-img"
                                src={LOGO_LIGHT}
                                alt="logo"
                            />
                            <img
                                className="logo-dark logo-img"
                                src={LOGO_DARK}
                                alt="logo-dark"
                            />
                        </a>
                    </div>
                    {/* <div className="nk-header-news d-none d-xl-block">
                        <div className="nk-news-list">
                            <a className="nk-news-item" href="#">
                                <div className="nk-news-icon">
                                    <em className="icon ni ni-card-view" />
                                </div>
                                <div className="nk-news-text"></div>
                            </a>
                        </div>
                    </div> */}
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="">
                                <Dropdown className="user-dropdown">
                                    <Dropdown.Toggle
                                        as={"a"}
                                        variant=""
                                        id="dropdown-basic"
                                        className="user-toggle"
                                    >
                                        <div className="user-avatar sm">
                                            <em className="icon ni ni-user-alt" />
                                        </div>
                                        <div className="user-info d-none d-md-block">
                                            <div className="user-status">Administrator</div>
                                            <div className="user-name dropdown-indicator">
                                                Admin
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                                        {/* <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                            <div className="user-card">
                                                <div className="user-avatar"><span>AB</span></div>
                                                <div className="user-info"><span className="lead-text">Abu Bin
                                                    Ishtiyak</span><span className="sub-text">info@softnio.com</span></div>
                                            </div>
                                        </div> */}
                                        {/* <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li><a href="user-profile-regular.html"><em className="icon ni ni-user-alt" /><span>View
                                                    Profile</span></a></li>
                                                <li><a href="user-profile-setting.html"><em className="icon ni ni-setting-alt" /><span>Account
                                                    Setting</span></a></li>
                                                <li><a href="user-profile-activity.html"><em className="icon ni ni-activity-alt" /><span>Login
                                                    Activity</span></a></li>
                                            </ul>
                                        </div> */}
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li>
                                                    <a className="cursor-pointer" onClick={handleLogout}>
                                                        <em className="icon ni ni-signout" />
                                                        <span>Sign out</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            {/* <li className="dropdown notification-dropdown me-n1">
                                <a
                                    href="#"
                                    className="dropdown-toggle nk-quick-nav-icon"
                                    data-bs-toggle="dropdown"
                                >
                                    <div className="icon-status icon-status-info">
                                        <em className="icon ni ni-bell" />
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-xl dropdown-menu-end dropdown-menu-s1">
                                    <div className="dropdown-head">
                                        <span className="sub-title nk-dropdown-title">
                                            Notifications
                                        </span>
                                        <a href="#">Mark All as Read</a>
                                    </div>
                                    <div className="dropdown-body">
                                        <div className="nk-notification">
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">
                                                        You have requested to
                                                        <span>Widthdrawl</span>
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">
                                                        Your <span>Deposit Order</span> is placed
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">
                                                        You have requested to
                                                        <span>Widthdrawl</span>
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">
                                                        Your <span>Deposit Order</span> is placed
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">
                                                        You have requested to
                                                        <span>Widthdrawl</span>
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon">
                                                    <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">
                                                        Your <span>Deposit Order</span> is placed
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-foot center">
                                        <a href="#">View All</a>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
