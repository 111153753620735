import Axios from "axios";
import { CREATE_TEMPLATE, DELETE_CARD, GET_ALL_CARDS, GET_ALL_USERS, GET_TEMPLATE, HEADER_IN_JSON, HEADER_IN_JSON_IMAGE, SIGN_IN } from "./api-path";

export const singInAdmin = async (send, token) => {
    const response = await Axios.post(SIGN_IN, send, { headers: HEADER_IN_JSON, validateStatus() { return true } })
    const data = response.data
    return data;
}


export const createTemplate = async (send, token) => {
    const response = await Axios.post(CREATE_TEMPLATE, send, { headers: HEADER_IN_JSON_IMAGE, validateStatus() { return true } })
    const data = response.data
    return data;
}
export const getTemplate = async (send, token) => {
    const response = await Axios.get(GET_TEMPLATE + send, { headers: HEADER_IN_JSON, validateStatus() { return true } })
    const data = response.data
    return data;
}
export const deleteTemplate = async (send, token) => {
    const response = await Axios.delete(DELETE_CARD + send, { headers: HEADER_IN_JSON, validateStatus() { return true } })
    const data = response.data
    return data;
}
export const getAllUsers = async (send, token) => {
    const response = await Axios.get(GET_ALL_USERS + send, { headers: HEADER_IN_JSON, validateStatus() { return true } })
    const data = response.data
    return data;
}
export const getAllCard = async (send, token) => {
    const response = await Axios.get(GET_ALL_CARDS + send, { headers: HEADER_IN_JSON, validateStatus() { return true } })
    const data = response.data
    return data;
}