export const SiteName = "Card Connect"

export const errorStyle = {
    backgroundColor: "rgba(232, 83, 71, 0.1)",
    border: "1px solid #e85347"
}

export const corpData = [{
    "corporation_name": "Janyx",
    "corporation_address": "27 Lighthouse Bay Circle",
    "corporation_city": "Jinggongqiao",
    "corporation_state": null,
    "corporation_country": "China",
    "corporation_postal_code": null,
    "corporation_phone": "962-565-7877",
    "corporation_email": "tyorkston0@statcounter.com",
    "corporation_website": "https://census.gov/odio/curabitur/convallis/duis/consequat/dui.json?odio=posuere&elementum=nonummy&eu=integer&interdum=non&eu=velit&tincidunt=donec&in=diam&leo=neque&maecenas=vestibulum&pulvinar=eget&lobortis=vulputate&est=ut&phasellus=ultrices&sit=vel&amet=augue&erat=vestibulum&nulla=ante&tempus=ipsum&vivamus=primis&in=in&felis=faucibus&eu=orci&sapien=luctus&cursus=et&vestibulum=ultrices&proin=posuere&eu=cubilia&mi=curae&nulla=donec&ac=pharetra&enim=magna&in=vestibulum&tempor=aliquet&turpis=ultrices&nec=erat&euismod=tortor&scelerisque=sollicitudin&quam=mi&turpis=sit&adipiscing=amet&lorem=lobortis&vitae=sapien&mattis=sapien&nibh=non&ligula=mi&nec=integer&sem=ac&duis=neque&aliquam=duis&convallis=bibendum&nunc=morbi&proin=non&at=quam&turpis=nec&a=dui&pede=luctus&posuere=rutrum&nonummy=nulla&integer=tellus&non=in&velit=sagittis&donec=dui&diam=vel&neque=nisl&vestibulum=duis&eget=ac&vulputate=nibh&ut=fusce&ultrices=lacus&vel=purus&augue=aliquet&vestibulum=at",
    "corporation_plan": "basic"
}, {
    "corporation_name": "Dynabox",
    "corporation_address": "472 Thackeray Lane",
    "corporation_city": "Rantepang",
    "corporation_state": null,
    "corporation_country": "Indonesia",
    "corporation_postal_code": null,
    "corporation_phone": "991-169-9565",
    "corporation_email": "hmerrin1@newyorker.com",
    "corporation_website": "https://about.com/congue/eget.js?pede=nullam&ullamcorper=molestie&augue=nibh",
    "corporation_plan": "premium"
}, {
    "corporation_name": "Feedmix",
    "corporation_address": "39 Dahle Place",
    "corporation_city": "Paris 17",
    "corporation_state": "Île-de-France",
    "corporation_country": "France",
    "corporation_postal_code": "75837 CEDEX 17",
    "corporation_phone": "329-946-0704",
    "corporation_email": "nimlach2@umich.edu",
    "corporation_website": "http://webs.com/suspendisse.png?morbi=quis&porttitor=orci&lorem=nullam&id=molestie&ligula=nibh&suspendisse=in&ornare=lectus&consequat=pellentesque&lectus=at&in=nulla&est=suspendisse&risus=potenti&auctor=cras&sed=in&tristique=purus&in=eu&tempus=magna&sit=vulputate&amet=luctus&sem=cum&fusce=sociis&consequat=natoque&nulla=penatibus&nisl=et&nunc=magnis&nisl=dis&duis=parturient&bibendum=montes&felis=nascetur&sed=ridiculus&interdum=mus",
    "corporation_plan": "basic"
}, {
    "corporation_name": "Einti",
    "corporation_address": "99497 Everett Park",
    "corporation_city": "Lameiro",
    "corporation_state": "Leiria",
    "corporation_country": "Portugal",
    "corporation_postal_code": "2425-317",
    "corporation_phone": "579-169-4791",
    "corporation_email": "ltwiggins3@xrea.com",
    "corporation_website": "https://chronoengine.com/urna/ut/tellus/nulla.jsp?nascetur=bibendum&ridiculus=imperdiet&mus=nullam&vivamus=orci&vestibulum=pede&sagittis=venenatis&sapien=non&cum=sodales&sociis=sed&natoque=tincidunt&penatibus=eu&et=felis&magnis=fusce&dis=posuere&parturient=felis&montes=sed&nascetur=lacus&ridiculus=morbi&mus=sem&etiam=mauris&vel=laoreet&augue=ut&vestibulum=rhoncus&rutrum=aliquet&rutrum=pulvinar&neque=sed&aenean=nisl&auctor=nunc&gravida=rhoncus&sem=dui&praesent=vel&id=sem&massa=sed&id=sagittis&nisl=nam&venenatis=congue&lacinia=risus&aenean=semper&sit=porta&amet=volutpat&justo=quam&morbi=pede&ut=lobortis&odio=ligula&cras=sit&mi=amet&pede=eleifend&malesuada=pede&in=libero&imperdiet=quis&et=orci&commodo=nullam&vulputate=molestie&justo=nibh&in=in&blandit=lectus&ultrices=pellentesque&enim=at&lorem=nulla&ipsum=suspendisse&dolor=potenti&sit=cras",
    "corporation_plan": "basic"
}, {
    "corporation_name": "Gabtype",
    "corporation_address": "441 Hauk Plaza",
    "corporation_city": "Xiangyun",
    "corporation_state": null,
    "corporation_country": "China",
    "corporation_postal_code": null,
    "corporation_phone": "425-941-1481",
    "corporation_email": "tdayly4@yolasite.com",
    "corporation_website": "https://indiatimes.com/pede/morbi/porttitor/lorem.aspx?suspendisse=nibh&ornare=in&consequat=lectus&lectus=pellentesque&in=at&est=nulla&risus=suspendisse&auctor=potenti&sed=cras&tristique=in&in=purus&tempus=eu&sit=magna&amet=vulputate&sem=luctus&fusce=cum&consequat=sociis&nulla=natoque&nisl=penatibus&nunc=et&nisl=magnis&duis=dis&bibendum=parturient&felis=montes&sed=nascetur&interdum=ridiculus&venenatis=mus&turpis=vivamus&enim=vestibulum&blandit=sagittis&mi=sapien&in=cum&porttitor=sociis&pede=natoque&justo=penatibus&eu=et&massa=magnis&donec=dis&dapibus=parturient&duis=montes&at=nascetur&velit=ridiculus&eu=mus&est=etiam&congue=vel&elementum=augue&in=vestibulum&hac=rutrum&habitasse=rutrum&platea=neque&dictumst=aenean&morbi=auctor&vestibulum=gravida&velit=sem&id=praesent&pretium=id&iaculis=massa&diam=id&erat=nisl&fermentum=venenatis&justo=lacinia&nec=aenean&condimentum=sit&neque=amet&sapien=justo&placerat=morbi&ante=ut&nulla=odio&justo=cras&aliquam=mi&quis=pede&turpis=malesuada&eget=in&elit=imperdiet&sodales=et&scelerisque=commodo&mauris=vulputate&sit=justo&amet=in&eros=blandit&suspendisse=ultrices&accumsan=enim",
    "corporation_plan": "standard"
}, {
    "corporation_name": "Camimbo",
    "corporation_address": "0 Ohio Court",
    "corporation_city": "São Sebastião do Caí",
    "corporation_state": null,
    "corporation_country": "Brazil",
    "corporation_postal_code": "95760-000",
    "corporation_phone": "153-653-0158",
    "corporation_email": "slarret5@bing.com",
    "corporation_website": "https://prweb.com/bibendum/morbi/non/quam/nec.xml?at=duis&turpis=bibendum&donec=morbi&posuere=non&metus=quam&vitae=nec&ipsum=dui&aliquam=luctus&non=rutrum&mauris=nulla&morbi=tellus&non=in&lectus=sagittis&aliquam=dui&sit=vel&amet=nisl&diam=duis&in=ac&magna=nibh&bibendum=fusce&imperdiet=lacus&nullam=purus&orci=aliquet&pede=at&venenatis=feugiat&non=non&sodales=pretium&sed=quis&tincidunt=lectus&eu=suspendisse&felis=potenti&fusce=in&posuere=eleifend&felis=quam&sed=a&lacus=odio&morbi=in&sem=hac&mauris=habitasse&laoreet=platea&ut=dictumst&rhoncus=maecenas&aliquet=ut&pulvinar=massa&sed=quis&nisl=augue&nunc=luctus&rhoncus=tincidunt&dui=nulla&vel=mollis&sem=molestie&sed=lorem&sagittis=quisque&nam=ut&congue=erat&risus=curabitur&semper=gravida&porta=nisi&volutpat=at&quam=nibh&pede=in&lobortis=hac&ligula=habitasse&sit=platea&amet=dictumst&eleifend=aliquam&pede=augue&libero=quam&quis=sollicitudin&orci=vitae&nullam=consectetuer&molestie=eget&nibh=rutrum&in=at&lectus=lorem&pellentesque=integer&at=tincidunt&nulla=ante&suspendisse=vel&potenti=ipsum&cras=praesent&in=blandit&purus=lacinia&eu=erat&magna=vestibulum&vulputate=sed&luctus=magna&cum=at&sociis=nunc&natoque=commodo&penatibus=placerat&et=praesent&magnis=blandit&dis=nam&parturient=nulla&montes=integer&nascetur=pede&ridiculus=justo",
    "corporation_plan": "standard"
}, {
    "corporation_name": "Quatz",
    "corporation_address": "154 Rigney Park",
    "corporation_city": "Rengo",
    "corporation_state": null,
    "corporation_country": "Chile",
    "corporation_postal_code": null,
    "corporation_phone": "943-653-0851",
    "corporation_email": "langier6@123-reg.co.uk",
    "corporation_website": "https://moonfruit.com/nonummy/maecenas/tincidunt/lacus/at/velit.js?leo=quisque&pellentesque=id&ultrices=justo&mattis=sit&odio=amet&donec=sapien&vitae=dignissim&nisi=vestibulum&nam=vestibulum&ultrices=ante&libero=ipsum&non=primis&mattis=in&pulvinar=faucibus&nulla=orci&pede=luctus&ullamcorper=et",
    "corporation_plan": "standard"
}, {
    "corporation_name": "Skippad",
    "corporation_address": "7 Scoville Way",
    "corporation_city": "Nacka",
    "corporation_state": "Stockholm",
    "corporation_country": "Sweden",
    "corporation_postal_code": "131 22",
    "corporation_phone": "505-136-6077",
    "corporation_email": "gwitherdon7@drupal.org",
    "corporation_website": "http://theatlantic.com/libero/non/mattis/pulvinar/nulla/pede/ullamcorper.jpg?sed=ac&justo=diam&pellentesque=cras&viverra=pellentesque&pede=volutpat&ac=dui&diam=maecenas&cras=tristique&pellentesque=est&volutpat=et&dui=tempus&maecenas=semper&tristique=est&est=quam&et=pharetra&tempus=magna&semper=ac&est=consequat&quam=metus&pharetra=sapien&magna=ut&ac=nunc&consequat=vestibulum&metus=ante&sapien=ipsum&ut=primis&nunc=in&vestibulum=faucibus&ante=orci&ipsum=luctus&primis=et&in=ultrices&faucibus=posuere&orci=cubilia&luctus=curae&et=mauris&ultrices=viverra&posuere=diam&cubilia=vitae&curae=quam&mauris=suspendisse&viverra=potenti&diam=nullam&vitae=porttitor&quam=lacus&suspendisse=at&potenti=turpis&nullam=donec&porttitor=posuere",
    "corporation_plan": "premium"
}, {
    "corporation_name": "Fatz",
    "corporation_address": "6239 Acker Center",
    "corporation_city": "Lere",
    "corporation_state": null,
    "corporation_country": "Nigeria",
    "corporation_postal_code": null,
    "corporation_phone": "197-474-3543",
    "corporation_email": "jvowles8@samsung.com",
    "corporation_website": "http://umich.edu/quis/turpis/sed/ante.jsp?volutpat=duis&sapien=at&arcu=velit&sed=eu&augue=est&aliquam=congue&erat=elementum&volutpat=in&in=hac&congue=habitasse&etiam=platea&justo=dictumst&etiam=morbi&pretium=vestibulum&iaculis=velit&justo=id&in=pretium&hac=iaculis&habitasse=diam&platea=erat&dictumst=fermentum&etiam=justo&faucibus=nec&cursus=condimentum&urna=neque&ut=sapien&tellus=placerat&nulla=ante&ut=nulla&erat=justo&id=aliquam&mauris=quis&vulputate=turpis&elementum=eget&nullam=elit&varius=sodales&nulla=scelerisque&facilisi=mauris&cras=sit&non=amet&velit=eros&nec=suspendisse&nisi=accumsan&vulputate=tortor&nonummy=quis&maecenas=turpis&tincidunt=sed&lacus=ante&at=vivamus&velit=tortor&vivamus=duis&vel=mattis&nulla=egestas&eget=metus&eros=aenean&elementum=fermentum&pellentesque=donec&quisque=ut&porta=mauris",
    "corporation_plan": "basic"
}, {
    "corporation_name": "Jaxworks",
    "corporation_address": "76828 Florence Trail",
    "corporation_city": "Padre Las Casas",
    "corporation_state": null,
    "corporation_country": "Dominican Republic",
    "corporation_postal_code": "10702",
    "corporation_phone": "867-368-8649",
    "corporation_email": "mpatershall9@msn.com",
    "corporation_website": "https://baidu.com/et/ultrices/posuere/cubilia/curae/donec.jsp?massa=praesent&donec=blandit&dapibus=lacinia&duis=erat&at=vestibulum&velit=sed&eu=magna&est=at&congue=nunc&elementum=commodo&in=placerat&hac=praesent&habitasse=blandit&platea=nam&dictumst=nulla&morbi=integer&vestibulum=pede&velit=justo&id=lacinia&pretium=eget&iaculis=tincidunt&diam=eget&erat=tempus&fermentum=vel&justo=pede&nec=morbi&condimentum=porttitor&neque=lorem&sapien=id&placerat=ligula&ante=suspendisse&nulla=ornare&justo=consequat&aliquam=lectus&quis=in&turpis=est&eget=risus&elit=auctor",
    "corporation_plan": "premium"
}]
