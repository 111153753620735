import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LOGO, LOGO_DARK, LOGO_LIGHT } from "../../utils/images/Images";
import { Link, useLocation } from "react-router-dom";


const MenuItem = ({ data }) => {
    const submenu = useRef(null);

    const handleSubMenu = (content) => {


        if (submenu.current.classList.contains("active")) {
            submenu.current.classList.remove("active");
        } else {
            submenu.current.classList.add("active");
        }
    };

    const linkProps = data?.isSubMenu ? {} : { to: data?.route };

    return (
        <li className={`nk-menu-item ${data?.isSubMenu ? "has-sub" : ""}`}>
            <Link {...linkProps} onClick={data?.function} className={`nk-menu-link  ${data?.isSubMenu ? "nk-menu-toggle cursor-pointer" : ""}`}>
                <span className="nk-menu-icon">
                    <em className={`icon ni ${data?.icon}`} />
                </span>
                <span className="nk-menu-text">{data?.name}</span>
            </Link>
            {data?.isSubMenu && (
                <ul className="nk-menu-sub">
                    {data?.subMenuItem?.map((item, index) => (
                        <li className="nk-menu-item" key={index.toString()} onClick={item?.function} >
                            <Link to={item?.route} className="nk-menu-link" >
                                <span className="nk-menu-text">{item?.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default function Sidebar() {
    const LINKS = [
        {
            id: 1,
            name: "Overview",
            route: "/",
            icon: "ni-grid-alt",
            isSubMenu: false,
            function: () => handleSideBar()
        },
        {
            id: 2,
            name: "All Users",
            route: "/all-users",
            icon: "ni-user-alt",
            isSubMenu: false,
            function: () => handleSideBar()
        },
        {
            id: 2,
            name: "Plans",
            route: "/plans",
            icon: "ni-sign-dollar",
            isSubMenu: false,
            function: () => handleSideBar()
        },
        {
            id: 2,
            name: "Corporation",
            route: "/corporation",
            icon: "ni-building",
            isSubMenu: false,
            function: () => handleSideBar()
        },
        // {
        //     id: 3,
        //     name: "All Generated Card",
        //     route: "/all-generated-card",
        //     icon: "ni-qr",
        //     isSubMenu: false,
        //     function: () => handleSideBar()
        // },
        // {
        //     id: 4,
        //     name: "My Business Card",
        //     icon: "ni-cards",
        //     isSubMenu: true,
        //     subMenuItem: [
        //         {
        //             id: "5",
        //             name: "My Cards",
        //             route: "cards",
        //             function: () => handleSideBar()
        //         },
        //         {
        //             id: "6",
        //             name: "Create New Card",
        //             route: "cards/create",
        //             function: () => handleSideBar()
        //         },
        //     ],
        // },

    ];
    const handleSideBar = () => {
        if (window.innerWidth < 500) {
            document.body.classList.remove("nav-show")
            document.querySelector(".nk-sidebar").classList.remove("nk-sidebar-active");
            document.querySelector('.nk-sidebar-overlay').remove()
            document.querySelector('.nk-sidebar-overlay').remove()
        }
    }

    useEffect(() => {


        var header = document.getElementById("nav-list");
        var btns = header.getElementsByClassName("nk-menu-item");
        for (var i = 0; i < btns.length; i++) {
            if (window.location.pathname === '/') {
                if (!btns[0].classList.contains("active")) {
                    btns[0].classList.add("active")
                }
            }
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[0].className = current[0]?.className.replace(" active", "");
                this.className += " active";
            });
        }
        return () => {

        }
    }, [])

    return (
        <div className="nk-sidebar nk-sidebar-fixed is-light">
            <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-menu-trigger">
                    <a
                        id="sidebarButton"
                        className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
                    >
                        <em className="icon ni ni-arrow-left" />
                    </a>
                    <a
                        id="sidebarButton"
                        className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
                    >
                        <em className="icon ni ni-menu" />
                    </a>
                </div>
                <div className="nk-sidebar-brand">
                    <Link to={"/"} className="logo-link nk-sidebar-logo">
                        <img className="logo-light logo-img" src={LOGO_LIGHT} alt="logo" />
                        <img className="logo-dark logo-img" src={LOGO_LIGHT} alt="logo-dark" />                     
                    </Link>
                </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-body">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu" id="nav-list">
                            {LINKS?.map((item, index) => (
                                <MenuItem key={index.toString()} data={item} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
