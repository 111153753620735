//BASE URL
export const HEADER_IN_JSON = {
    "Accept": "application/json",
    "Content-Type": "application/json; charset=utf-8",
}


export const HEADER_IN_JSON_IMAGE = {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
}
const API = process.env.REACT_APP_BASE_URL;


export const SIGN_IN = `${API}admin/signIn`
export const CREATE_TEMPLATE = `${API}template/save`
export const GET_TEMPLATE = `${API}template/getAll/`
export const DELETE_CARD = `${API}template/delete/`


export const GET_ALL_USERS = `${API}user/getAll/`
export const GET_ALL_CARDS = `${API}card/getAll/`