import React from 'react'
import { Link } from 'react-router-dom'

const CreateCorp = () => {
  return (
    <div className='nk-block'>
      <div className='row g-gs'>
        <div className='col-lg-8'>
          <div className='nk-block-head d-flex align-items-end justify-content-between'>
            <div className='nk-block-head-content w-60'>
              <h4 className='nk-block-title'>Add Corporation</h4>
              <div className='nk-block-des'>
                {/* <p>Create your plans here.</p> */}
              </div>
            </div>
          </div>
          <form>
            <div className='card card-bordered'>
              <div className='card-inner row'>
                <div className='form-group col-lg-12'>
                  <div className='form-control-wrap'>
                    <label>Name <span className='text-danger'>*</span></label>
                    <input
                      type='text'
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='form-group col-lg-6'>
                  <div className='form-control-wrap'>
                    <label>Mobile <span className='text-danger'>*</span></label>
                    <input
                      type='text'
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='form-group col-lg-6'>
                  <div className='form-control-wrap'>
                    <label>Email <span className='text-danger'>*</span></label>
                    <input
                      type='email'
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='form-group col-lg-6'>
                  <label>Select Plan <span className='text-danger'>*</span></label>
                  <select className='form-select mb-3'>
                    <option disabled selected>Choose plan</option>
                    <option>Starter Package($9.99)</option>
                    <option>Professional Package($19.99)</option>
                    <option>Enterprise Package($49.99)</option>
                    <option>Essentials($0 Free)</option>                    
                  </select>
                  <Link to={'/plans/create'} className='btn btn-dim  btn-outline-primary'>Create New</Link>
                </div>
                <div className='mt-4'>
                  <button type='submit' className='btn btn-primary'>
                    Save
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateCorp