import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../shared/Sidebar'
import Header from '../shared/Header'
export default class Container extends Component {
    state = {
        isMounted: false
    }

    componentDidMount() {
        let sidebarButton = document.getElementById("sidebarButton");
        let headerButton = document.getElementById("headerButton");
        let createDiv = document.createElement("div");
        createDiv.classList.add("nk-sidebar-overlay")
        if (window.innerWidth < 500) {
            sidebarButton.addEventListener("click", function () {
                document.body.classList.remove("nav-show")
                document.querySelector(".nk-sidebar").classList.remove("nk-sidebar-active");
                document.querySelector(".nk-main").removeChild(createDiv)
            })

            headerButton.addEventListener("click", function () {
                document.body.classList.add("nav-show");
                document.querySelector(".nk-sidebar").classList.add("nk-sidebar-active");
                document.querySelector(".nk-main").append(createDiv)
            })

            createDiv.addEventListener("click", function () {
                document.querySelector('.nk-sidebar-overlay').remove()
                // document.querySelector('.nk-sidebar-overlay').remove()
                document.querySelector(".nk-sidebar").classList.remove("nk-sidebar-active");
                document.body.classList.remove("nav-show")
            })
        }
    }


    render() {
        return (
            <div className="nk-app-root">
                <div className="nk-main">
                    <Sidebar />
                    <div className="nk-wrap">
                        <Header isAuth={this.props.isAuth} />
                        <div className="nk-content">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
