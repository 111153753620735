import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SiteName, errorStyle } from '../../../utils/strings/Strings'
import { singInAdmin } from '../../../services/api/api-services'
import { toast } from 'react-hot-toast'
import { LOGO_DARK, LOGO_LIGHT } from '../../../utils/images/Images'

export default function Login({ isAuth }) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [isPasswordShow, setIsPasswordShow] = useState(false)
    const [disable, setDisable] = useState(false)
    const handleFormValidation = (event) => {
        event.preventDefault()
        if (email === "" || password === "") {
            if (email === "") {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }

        } else {
            handleSubmitForm();
        }
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
        if (email !== "") {
            setEmailError(false)
        }
    }
    const handlePassword = (event) => {
        setPassword(event.target.value)
        if (password !== "") {
            setPasswordError(false)
        }
    }

    const handleSubmitForm = () => {
        setDisable(true)
        const data = {
            email,
            password
        }
        const toastId = toast.loading("Logging...");

        singInAdmin(data).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId)
                toast.success("Logging successfully!")
                localStorage.setItem("token", response?.token)
                isAuth && isAuth();
                setDisable(false);

            } else {
                toast.dismiss(toastId)
                toast.error(response?.msg)
                setDisable(false);
            }
        }).catch((err) => console.log((err)));
    }
    const handleShowPassword = () => setIsPasswordShow(!isPasswordShow)

    return (
        <div className='nk-wrap nk-wrap-nosidebar'>
            <div className='nk-content '>
                <div className='nk-block nk-block-middle nk-auth-body  wide-xs'>
                    <div className='brand-logo pb-4 text-center'>
                        <Link to={'/'} className='logo-link'>
                            <img
                                className='logo-light logo-img logo-img-lg'
                                src={LOGO_LIGHT}
                                alt='logo' />
                            <img
                                className='logo-dark logo-img logo-img-lg'
                                src={LOGO_DARK}
                                alt='logo-dark' />
                           

                        </Link>
                    </div>
                    <div className='card card-bordered'>
                        <div className='card-inner card-inner-lg'>
                            <div className='nk-block-head'>
                                <div className='nk-block-head-content'>
                                    <h4 className='nk-block-title'>Login</h4>
                                    <div className='nk-block-des'>
                                        <p>
                                            Access the {SiteName} panel using your email and password.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form autoComplete="off" id='login-form' onSubmit={handleFormValidation}>
                                <div className='form-group'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='email-address'>
                                            Email address
                                        </label>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <input
                                            role="presentation"
                                            autoComplete="off"
                                            onChange={handleEmail}
                                            type='email'
                                            className='form-control'
                                            id='email-address'
                                            style={emailError ? errorStyle : {}}
                                            placeholder='Enter your email address' />
                                        {emailError && <small className='invalid-feedback d-block'>Email address must be filled out </small>}

                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='password'>
                                            Password
                                        </label>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <a onClick={handleShowPassword} className='form-icon form-icon-right passcode-switch'>
                                            <em className={`passcode-icon icon-show icon ni ${isPasswordShow ? "ni-eye" : "ni-eye-off"}`} />
                                        </a>
                                        <input
                                            role="presentation"
                                            autoComplete="new-password"
                                            onChange={handlePassword}
                                            type={isPasswordShow ? 'text' : 'password'}
                                            className='form-control'
                                            id='password'
                                            style={passwordError ? errorStyle : {}}
                                            placeholder='Enter your passcode' />
                                        {passwordError && <small className='invalid-feedback d-block'>Password must be filled out </small>}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <button disabled={disable} type='submit' className='btn btn-primary btn-block'>
                                        Login
                                    </button>
                                </div>
                            </form>
                            {/* <div className='form-note-s2 text-center pt-4'>
                                New on our platform? <Link to={'register'}>Create an account</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
