import React from 'react'
import MultipleInputs from '../../../components/multipleInputs/MultipleInputs'

const CreatePlan = () => {
  return (
   
      <div className='nk-block'>
        <div className='row g-gs'>
          <div className='col-lg-8'>
            <div className='nk-block-head d-flex align-items-end justify-content-between'>
              <div className='nk-block-head-content w-60'>
                <h4 className='nk-block-title'>Create Plan</h4>
                <div className='nk-block-des'>
                  <p>Create your plans here.</p>
                </div>
              </div>              
            </div>
            <form>
              <div className='card card-bordered'>
                <div className='card-inner row'>
                  <div className='form-group col-lg-12'>
                    <div className='form-control-wrap'>
                      <label>Plan title <span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='form-group col-lg-6'>
                    <div className='form-control-wrap'>
                      <label>Price <span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='form-group col-lg-6'>
                    <div className='form-control-wrap'>
                      <label>Cards <span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='form-group col-lg-6'>
                    <div className='form-control-wrap'>                      
                      <MultipleInputs
                        dataObject={{
                          value: "lll",
                          label:"ssss"                          
                        }}
                        isMultiple={true}
                        setData={() => { }}
                        data={[1]}
                        label='Features'
                        isMandatory
                      />
                    </div>                  
                  </div>
                  <div className='form-group'>
                    <div className='form-control-wrap'>
                      <textarea
                        rows={4}
                        type='text'
                        className='form-control'
                        id='html' />
                    </div>
                  </div>
                  <div className='mt-4'>
                    <button type='submit' className='btn btn-primary'>
                      Save
                    </button>
                  </div>
                </div>

              </div>  
            </form>
          </div>
        </div>
      </div>
   
  )
}

export default CreatePlan