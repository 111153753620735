import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { DigitalCardLayouts, LoginPage, DigitalCardLayoutsCreate, Overview, AllUsers, AllCards, Corporation, CreatePlan, Plans, CorporationCreate } from './routes/routes'
import Container from './components/layouts/Container';
import './assets/css/dashlite583f.css?ver=3.1.3'
import './assets/css/theme583f.css?ver=3.1.3'
import AuthLayout from './components/layouts/AuthLayout';
import CardContainer from './pages/dashboard/digital-business-card-layout/CardContainer';
import SectionLayout from './components/layouts/SectionLayout';


export default function App() {
    const [isValid, setIsValid] = useState(false);
    const navigation = useNavigate();

    const handleValidForm = () => {
        const token = localStorage.getItem('token');
        if (token !== null) {
            setIsValid(true);
        } else {
            setIsValid(false);
            navigation('/');
        }
    }

    useEffect(() => {
        handleValidForm()
        return () => { }
    }, [])

    return (
        !isValid ?
            <Routes>
                <Route path='/' Component={AuthLayout}>
                    <Route index element={<LoginPage isAuth={handleValidForm} />} />
                </Route>
            </Routes> :
            <Routes>
                <Route path='/' element={<Container isAuth={handleValidForm} />}>
                    <Route index Component={Overview} />
                    <Route path='all-users' Component={AllUsers} />
                    <Route path='all-generated-card' Component={AllCards} />
                    <Route path='corporation' Component={SectionLayout} >                       
                        <Route index Component={Corporation} />
                        <Route path='create' Component={CorporationCreate} />
                    </Route>
                    <Route path='plans' Component={SectionLayout} >                       
                        <Route index Component={Plans} />
                        <Route path='create' Component={CreatePlan} />
                    </Route>
                    <Route path='cards' Component={CardContainer}>
                        <Route index Component={DigitalCardLayouts} />
                        <Route path='create' Component={DigitalCardLayoutsCreate} />
                    </Route>
                </Route>
            </Routes>
    )
}
