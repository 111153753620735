import React, { useEffect, useState } from 'react'
import User from '../users/User';
import { getAllCard, getAllUsers } from '../../services/api/api-services';

export default function Overview() {

    const [userCount, setUserCount] = useState(0);
    const [cardCount, setCardCount] = useState(0);

    useEffect(() => {
        getUsers("1");
    }, [])

    const getUsers = (activePage,) => {

        getAllUsers(activePage).then(response => {
            if (response?.err === false) {
                if (response?.users?.length !== 0) {
                    setUserCount(response?.count);

                } else {
                    setUserCount(0)
                }
            }
        }).catch(err => console.log(err))

        getAllCard(activePage).then(response => {
            if (response?.err === false) {
                if (response?.card?.length !== 0) {
                    setCardCount(response?.count);
                } else {
                    setCardCount(0)
                }
            }
        }).catch(err => console.log(err))
    }


    return (
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <div class="nk-block-head nk-block-head-sm">
                        <div class="nk-block-between">
                            <div class="nk-block-head-content">
                                <h3 class="nk-block-title page-title">Overview</h3>
                            </div>
                        </div>
                    </div>
                    <div class="nk-block">
                        <div class="row g-gs">
                            <div class="col-xxl-3 col-sm-6">
                                <div class="card">
                                    <div class="nk-ecwg nk-ecwg6">
                                        <div class="card-inner">
                                            <div class="card-title-group">
                                                <div class="card-title">
                                                    <h6 class="title">All Users</h6>
                                                </div>
                                            </div>
                                            <div class="data">
                                                <div class="data-group">
                                                    <div class="h4 mt-3">{userCount}</div>
                                                    <div class="nk-ecwg6-ck">
                                                        <div class="chartjs-size-monitor">
                                                            <div class="chartjs-size-monitor-expand">
                                                                <div class=""></div>
                                                            </div>
                                                            <div class="chartjs-size-monitor-shrink">
                                                                <div class=""></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="info">
                                                    <span class="change up text-danger"
                                                    ><em class="icon ni ni-arrow-long-up"></em>4.63%</span
                                                    ><span> vs. last week</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-sm-6">
                                <div class="card">
                                    <div class="nk-ecwg nk-ecwg6">
                                        <div class="card-inner">
                                            <div class="card-title-group">
                                                <div class="card-title">
                                                    <h6 class="title">All Generated Card</h6>
                                                </div>
                                            </div>
                                            <div class="data">
                                                <div class="data-group">
                                                    <div class="h4 mt-3">{cardCount}</div>
                                                    <div class="nk-ecwg6-ck">
                                                        <div class="chartjs-size-monitor">
                                                            <div class="chartjs-size-monitor-expand">
                                                                <div class=""></div>
                                                            </div>
                                                            <div class="chartjs-size-monitor-shrink">
                                                                <div class=""></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="info">
                                                    <span class="change up text-danger"
                                                    ><em class="icon ni ni-arrow-long-up"></em>4.63%</span
                                                    ><span> vs. last week</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
