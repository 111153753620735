import React from 'react'
import { Outlet } from 'react-router-dom'

const SectionLayout = () => {
    return (
        <div className='container-fluid'>
            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='components-preview  mx-auto'>
                        <div className='nk-block nk-block-lg'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionLayout