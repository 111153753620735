import React from 'react'
import { Link } from 'react-router-dom'

const Plans = () => {
  return (
    <>
      <div className='nk-block-head d-flex align-items-end justify-content-between'>
        <div className='nk-block-head-content w-60'>
          <h4 className='nk-block-title'>All Plans ({4})</h4>
          <div className='nk-block-des'>
            <p>Create and manage your plans here.</p>
          </div>
        </div>
        <div>
          <Link to={'create'} className='btn btn-primary'>Add New</Link>
        </div>
      </div>
      <div className='card card-bordered card-preview'>
        <div className='card-inner'>
          <div className='row g-3'>
            <div className='col-lg-3 mb-1'>
              <div class="card card-bordered">
                <div class="card-inner">
                  <h5 class="card-title">Starter Package</h5>
                  <h6 class="card-subtitle mb-2">Monthly Fee: $9.99</h6>
                  <p class="card-text">This plan is suitable for individuals or small businesses with limited ID card generation needs.</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Unlimited access to basic ID card templates.</li>
                  <li class="list-group-item">Generate up to 50 ID cards per month.</li>
                  <li class="list-group-item">Standard customer support via email.</li>
                  <li class="list-group-item">Watermarked ID cards.</li>
                </ul>
                <div class="card-inner py-2 ">
                  <a href="#" class="card-link">Update</a>
                  <a href="#" class="card-link text-danger">Delete</a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 mb-1'>
              <div class="card card-bordered">
                <div class="card-inner">
                  <h5 class="card-title">Professional Package</h5>
                  <h6 class="card-subtitle mb-2">Monthly Fee: $19.99</h6>
                  <p class="card-text">This plan caters to businesses and organizations with moderate to high ID card generation requirements, providing additional customization options and improved support.</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Access to a wide range of templates including premium designs.</li>
                  <li class="list-group-item">Unlimited ID card generation.</li>
                  <li class="list-group-item">Priority customer support with a guaranteed response time.</li>
                  <li class="list-group-item">No watermarks on ID cards.</li>
                  <li class="list-group-item">Option to customize and save templates.</li>
                </ul>
                <div class="card-inner py-2 ">
                  <a href="#" class="card-link">Update</a>
                  <a href="#" class="card-link text-danger">Delete</a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 mb-1'>
              <div class="card card-bordered">
                <div class="card-inner">
                  <h5 class="card-title">Enterprise Package</h5>
                  <h6 class="card-subtitle mb-2">Monthly Fee: $49.99</h6>
                  <p class="card-text">Designed for large enterprises and organizations with high-volume ID card generation needs, the Premium Plan offers advanced customization and integration options along with top-tier support.

                  </p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">All features from the Standard Plan.</li>
                  <li class="list-group-item">Advanced customization options, including logos and branding.</li>
                  <li class="list-group-item">API integration for seamless ID card generation within existing systems.</li>
                  <li class="list-group-item">Dedicated account manager for personalized support.</li>
                  <li class="list-group-item">Priority access to new features and updates.</li>
                </ul>
                <div class="card-inner py-2 ">
                  <a href="#" class="card-link">Update</a>
                  <a href="#" class="card-link text-danger">Delete</a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 mb-1'>
              <div class="card card-bordered">
                <div class="card-inner">
                  <h5 class="card-title">Essentials</h5>
                  <h6 class="card-subtitle mb-2">Monthly Fee: $0 (Free)</h6>
                  <p class="card-text">This plan is ideal for individuals or small organizations with minimal ID card generation needs. It allows users to experience the platform without any financial commitment.</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Limited set of basic ID card templates.</li>
                  <li class="list-group-item">Generate up to 10 ID cards per month.</li>
                  <li class="list-group-item">Watermarked ID cards.</li>
                  <li class="list-group-item">Email customer support with a response time of 48 hours.</li>
                  <li class="list-group-item">Basic customization options.</li>
                </ul>
                <div class="card-inner py-2 ">
                  <a href="#" class="card-link">Update</a>
                  <a href="#" class="card-link text-danger">Delete</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default Plans


// < div className = 'card card-bordered card-preview' >
//   <div className='card-inner'>
//     <div class="card">
//       <div class="card-inner">
//         <h5 class="card-title">Card title</h5>
//         <h6 class="card-subtitle mb-2">Card subtitle</h6>
//         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//         <a href="#" class="card-link">Card link</a>
//         <a href="#" class="card-link">Another link</a>
//       </div>
//     </div>
//     {/* <div className=' table-responsive'>
//           <table className='nowrap nk-tb-list nk-tb-ulist '>
//             <thead>
//               <tr className='nk-tb-item nk-tb-head'>
//                 <th className='nk-tb-col' style={{}}>
//                   <span className='sub-text'>Name</span>
//                 </th>
//                 <th className='nk-tb-col'>
//                   <span className='sub-text'>Email</span>
//                 </th>
//                 <th className='nk-tb-col'>
//                   <span className='sub-text'>Created At</span>
//                 </th>
//                 <th className='nk-tb-col nk-tb-col-tools text-end' />
//               </tr>
//             </thead>
//             <tbody>
//               {user?.length > 0 ? user?.map((item, index) => (
//                 <tr className='nk-tb-item' key={item?._id}>
//                   <td className='nk-tb-col tb-col-md'>
//                     <span>{(activePage - 1) * 20 + index + 1}</span>
//                   </td>
//                   <td className='nk-tb-col'>
//                     <div className='user-card'>
//                       <div className='user-avatar bg-dim-primary'>
//                         {item?.profilePhoto ? <img src={item?.profilePhoto} style={{ width: 40, height: 40, maxWidth: "unset", maxHeight: "unset", objectFit: 'cover' }} /> : <span className='text-uppercase'>{item?.firstName?.charAt(0)}{item?.lastName?.charAt(0)}</span>}
//                       </div>
//                       <div className='user-info'>
//                         <span className='tb-lead'>{item?.firstName} {item?.lastName}</span>
//                       </div>
//                     </div>
//                   </td>
//                   <td className='nk-tb-col tb-col-md'>
//                     <span>{item?.email}</span>
//                   </td>
//                   <td className='nk-tb-col tb-col-md'>
//                     <span>{moment(item?.createdAt).format("DD/MMM/YYYY")}</span>
//                   </td>
//                 </tr>
//               )) : isEmpty ? <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>No data found!</td></tr> : <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>Loading....</td></tr>}
//             </tbody>
//           </table>
//         </div> */}
//     {/* <div className='d-flex align-items-center justify-content-center mt-4'>
//           <Pagination
//             activePage={activePage}
//             itemsCountPerPage={20}
//             totalItemsCount={totalLength}
//             pageRangeDisplayed={5}
//             onChange={handlePageChange}
//             innerClass='pagination'
//             itemClass='page-item'
//             linkClass='page-link'
//           />
//         </div> */}
//   </div>
//     </div >