import { createSlice } from "@reduxjs/toolkit";


export const UserSlice = createSlice({
    name: "user",
    initialState: {

    },
    reducers: {
        user: (state, action) => {
            state.user = action.payload;
        },
    },
})



export const { user } = UserSlice.actions;

export const selectUser = (state) => state.user;

export default UserSlice.reducer;

