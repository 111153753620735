import React from 'react'
import { Link } from 'react-router-dom'
import { corpData } from '../../utils/strings/Strings'

const Corporations = () => {
  return (
    <>
      <div className='nk-block-head d-flex align-items-end justify-content-between'>
        <div className='nk-block-head-content w-60'>
          <h4 className='nk-block-title'>All Corp ({4})</h4>
          <div className='nk-block-des'>
            <p>Create and manage your corp. here.</p>
          </div>
        </div>
        <div>
          <Link to={'create'} className='btn btn-primary'>Add New</Link>
        </div>
      </div>
      <div className="card card-preview card-bordered">
        <table className="table table-tranx">
          <thead>
            <tr className="tb-tnx-head">
              <th className="tb-tnx-id">
                <span className>#</span>
              </th>
              <th className="tb-tnx-info">
                <span className="tb-tnx-desc d-none d-sm-inline-block">
                  <span>Bill For</span>
                </span>
                <span className="tb-tnx-date d-md-inline-block d-none">
                  <span className="d-md-none">Date</span>
                  <span className="d-none d-md-block">
                    <span>Mobile</span>
                    <span>Email</span>
                  </span>
                </span>
              </th>
              <th className="tb-tnx-info">
                <span className="d-md-none">Date</span>
                <span className="d-none d-md-block">
                <span>Plan</span>
                </span>

              </th>
              <th className="tb-tnx-amount">
                <span className="tb-tnx-total">Total</span>
                <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
              </th>
            </tr>
          </thead>
          <tbody>


            {corpData?.map((item, index) => {
              return (<tr className="tb-tnx-item" key={item?._id}>
                <td className="tb-tnx-id">
                  <a href="#"><span>{index+1}</span></a>
                </td>
                <td className="tb-tnx-info">
                  <div className="tb-tnx-desc">
                    <span className="title">{item?.corporation_name}</span>
                  </div>
                  <div className="tb-tnx-date">
                    <span className="date">{item?.corporation_phone}</span>
                    <span className="email">{item.corporation_email}</span>
                  </div>
                </td>
                <td>
                  <span className="tb-tnx-status text-capitalize badge bg-primary">{item?.corporation_plan}</span>
                </td>
                <td className="tb-tnx-amount">
                  <div className="tb-tnx-total"><span className="amount">$599.00</span></div>
                  <div className="tb-tnx-status"><span className="badge badge-dot bg-warning">Due</span></div>
                </td>
              </tr>)
            })}


          </tbody>
        </table>
      </div>

    </>
  )
}

export default Corporations