import React, { useEffect, useState } from 'react'
import { getAllUsers } from '../../services/api/api-services'
import Pagination from 'react-js-pagination';

import moment from 'moment';

export default function User() {

    const [totalLength, setTotalLength] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isEmpty, setEmpty] = useState(false)
    const [user, setUser] = useState([])
    useEffect(() => {
        getUsers(1);


        return () => {

        }
    }, [])

    const getUsers = (activePage,) => {
        getAllUsers(activePage).then(response => {
            if (response?.err === false) {
                if (response?.users?.length !== 0) {
                    setUser(response?.users);
                    setEmpty(false)
                    setTotalLength(response?.count)
                } else {
                    setEmpty(true)
                }
            }
        }).catch(err => console.log(err))
    }


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        getUsers(pageNumber);
    }
    return (
        <div className='container-fluid'>
            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='components-preview  mx-auto'>
                        <div className='nk-block nk-block-lg'>
                            <div className='nk-block-head d-flex align-items-start justify-content-between'>
                                <div className='nk-block-head-content w-60'>
                                    <h4 className='nk-block-title'>All Users ({totalLength})</h4>
                                    <div className='nk-block-des'>
                                        {/* <p>Create and manage your personal cards here.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='card card-bordered card-preview'>
                                <div className='card-inner'>
                                    <div className=' table-responsive'>
                                        <table className='nowrap nk-tb-list nk-tb-ulist '>
                                            <thead>
                                                <tr className='nk-tb-item nk-tb-head'>
                                                    <th className='nk-tb-col' style={{}}>
                                                        <span className='sub-text'>Name</span>
                                                    </th>
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>Email</span>
                                                    </th>
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>Created At</span>
                                                    </th>
                                                    <th className='nk-tb-col nk-tb-col-tools text-end' />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {user?.length > 0 ? user?.map((item, index) => (
                                                    <tr className='nk-tb-item' key={item?._id}>
                                                        <td className='nk-tb-col tb-col-md'>
                                                            <span>{(activePage - 1) * 20 + index + 1}</span>
                                                        </td>
                                                        <td className='nk-tb-col'>
                                                            <div className='user-card'>
                                                                <div className='user-avatar bg-dim-primary'>
                                                                    {item?.profilePhoto ? <img src={item?.profilePhoto} style={{ width: 40, height: 40, maxWidth: "unset", maxHeight: "unset", objectFit: 'cover' }} /> : <span className='text-uppercase'>{item?.firstName?.charAt(0)}{item?.lastName?.charAt(0)}</span>}
                                                                </div>
                                                                <div className='user-info'>
                                                                    <span className='tb-lead'>{item?.firstName} {item?.lastName}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='nk-tb-col tb-col-md'>
                                                            <span>{item?.email}</span>
                                                        </td>
                                                        <td className='nk-tb-col tb-col-md'>
                                                            <span>{moment(item?.createdAt).format("DD/MMM/YYYY")}</span>
                                                        </td>
                                                    </tr>
                                                )) : isEmpty ? <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>No data found!</td></tr> : <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>Loading....</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center mt-4'>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={20}
                                            totalItemsCount={totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            innerClass='pagination'
                                            itemClass='page-item'
                                            linkClass='page-link'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
