import { useState } from 'react';



export default function MultipleInputs({ data, setData, dataObject, label, name, isMultiple, isMandatory }) {
    const [error, setError] = useState("")
    const handleMultiple = (event, index) => {
        let { name, value } = event.target;
        // let jasper = Object.assign({}, ...data);
        // let onChangeValue = [...data];
        // jasper[index][name] = value;
        // onChangeValue[index][name] = value;

        const newState = data.map((obj, i) => {


            if (index === i && name !== "type") {
                return { ...obj, value: value, };
            }
            if (index === i && name === "type") {
                return { ...obj, type: value, };
            }
            return obj;
        });
        setData(newState)

    }

    const handleAdd = () => {
        setData((prev) => [...prev, dataObject])
    }
    const handleDelete = (i) => {
        if (data.length === 1) {
            setError("At least one field must be visible or the text needs to be removed")
            return
        }
        const deleteVal = [...data];

        deleteVal.splice(i, 1);
        setData(deleteVal)
    }

    return (
        <div className={isMultiple ? "col-sm-12" : "col-sm-6"}>
            <label htmlFor={label}>{label} {isMandatory && <span className='text-danger'>*</span>}</label>
            {data?.map((item, index) => (
                <div div className={`row g-2 ${isMultiple && 'mb-2'} align-items-center`} key={index.toString()} >
                    <div className={isMultiple ? "col-11" : "col-sm-12"}>
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <input
                                    onChange={(event) => handleMultiple(event, index)}
                                    name={name}
                                    autocapitalize="none"
                                    value={item?.value}
                                    type="text" className="form-control"
                                    id={label} />
                            </div>
                        </div>
                    </div>
                    {isMultiple && <div className="col-1">
                        <div className="form-group input-group-append">
                            <button onClick={() => handleDelete(index)} className='btn btn-icon btn-sm btn-danger' disabled={data?.length === 1} style={{ opacity: data?.length === 1 ? 0.5 : 1 }}>
                                <em className='icon ni ni-trash' />
                            </button>
                        </div>
                    </div>}
                    {error && <div className='col-lg-12'>
                        <small className='invalid-feedback d-block'>{error}</small>
                    </div>}
                </div>
            ))
            }

            {isMultiple && <div className='row mt-2'>
                <div className="col-sm-12">
                    <button className="btn btn-primary btn-sm mt-0" type='button' onClick={handleAdd}>
                        <em className='icon ni ni-plus' style={{ fontSize: 13 }} />&nbsp;Add More
                    </button>
                </div>
            </div>
            }
        </div >
    )
}
